import React from "react"

import Heading from "../../components/Heading"

import styles from "./zoom.module.scss"

const Zoom = () => {

  return(
    <section className={styles.module}>
      <header className={styles.header}>
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          TM x Zoom*
        </Heading>
        <p>*An unofficial collaboration.</p>
        <p>Zoom is now more important to our personal and professional lives than (dare we say it?) the right choice in shoes.</p>
        <p>Since you’re probably doing at least five of these per day, mix it up by downloading these TM-approved backgrounds and adding one to your next call.</p>
        <p className={styles.subparagraph}>Tap download, save the image, and get to Zooming.</p>
      </header>
      
      <ul className={styles.wallpaperList}>
        <li className={styles.item} id="office">
          <img srcSet="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_office_650x.progressive.jpg?v=1586203096 650w,
                   https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_office_1300x.progressive.jpg?v=1586203096 1300w"
               sizes="(max-width: 450px) 650px,
               1300px"
               src="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_office_1300x.progressive.jpg?v=1586203096"
               alt="Tamara Mellon Office" />
          <p>
            Tamara’s Home Office (Yes, Actually)
            <a href="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_office.jpg?v=1586203096" download
               target="_blank" rel="noopener noreferrer">Download</a>
          </p>
          <p>For when you have a meeting with your boss’s boss, and want your workspace to read more “polished professional” than “cat owner who recently moved.”</p>
        </li>
        <li className={styles.item} id="birdbush">
          <img srcSet="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaulaKudacki_chaud_650x.progressive.jpg?v=1586203095 650w,
                   https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaulaKudacki_chaud_1300x.progressive.jpg?v=1586203095 1300w"
               sizes="(max-width: 450px) 650px,
               1300px"
               src="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaulaKudacki_chaud_1300x.progressive.jpg?v=1586203095"
               alt="Tamara Mellon Chaud" />
          <p>
            Bird in the Bush
            <a href="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaulaKudacki_chaud.jpg?v=1586203095"
               download target="_blank" rel="noopener noreferrer">Download</a>
          </p>
          <p>We’re over it. You’re over it. In a few weeks, we’re all going to be even more over it. That’s where this comes in.</p>
        </li>
        <li className={styles.item} id="dance">
          <img srcSet="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaolaKudacki_disco_650x.progressive.jpg?v=1586203095 650w,
                   https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaolaKudacki_disco_1300x.progressive.jpg?v=1586203095 1300w"
               sizes="(max-width: 450px) 650px,
               1300px"
               src="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaolaKudacki_disco_1300x.progressive.jpg?v=1586203095"
               alt="Tamara Mellon Disco" />
          <p>
            Let’s Dance
            <a href="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaolaKudacki_disco.jpg?v=1586203095"
               download target="_blank" rel="noopener noreferrer">Download</a>
          </p>
          <p>Maybe you’re having drinks with friends — maybe you’re on a first-date. Either way, dress it up (without getting dressed up).</p>
        </li>
        <li className={styles.item} id="milk">
          <img srcSet="https://cdn.shopify.com/s/files/1/1103/4464/files/Tamara_Mellon_Paola_Kudacki_spilledMilk_650x.progressive.jpg?v=1586203094 650w,
                   https://cdn.shopify.com/s/files/1/1103/4464/files/Tamara_Mellon_Paola_Kudacki_spilledMilk_1300x.progressive.jpg?v=1586203094 1300w"
               sizes="(max-width: 450px) 650px,
               1300px"
               src="https://cdn.shopify.com/s/files/1/1103/4464/files/Tamara_Mellon_Paola_Kudacki_spilledMilk_1300x.progressive.jpg?v=1586203094"
               alt="Tamara Mellon Spilt Milk" />
          <p>
            Don’t Cry Over Spilt Milk
            <a
              href="https://cdn.shopify.com/s/files/1/1103/4464/files/Tamara_Mellon_Paola_Kudacki_spilledMilk.jpg?v=1586203094"
              download target="_blank" rel="noopener noreferrer">Download</a>
          </p>
          <p>Unless it means you have to brave the grocery store again. Then definitely cry.</p>
        </li>
        <li className={styles.item} id="closet">
          <img srcSet="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_Closet_650x.progressive.jpg?v=1586549419 650w,
                   https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_Closet_1300x.progressive.jpg?v=1586549419 1300w"
               sizes="(max-width: 450px) 650px,
               1300px"
               src="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_Closet_1300x.progressive.jpg?v=1586549419"
               alt="Tamara Mellon Shoe Closet" />
          <p>
            TM Shoe Closet
            <a href="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_Closet.jpg?v=1586549419" download
               target="_blank" rel="noopener noreferrer">Download</a>
          </p>
          <p>At least appear to look like you took that whole “use this time for home organization” suggestion really seriously when you’re talking to your mother-in-law.</p>
        </li>
        <li className={styles.item} id="netflix">
          <img srcSet="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_Paola_Kudacki_Linger_650x.progressive.jpg?v=1586203095 650w,
                   https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_Paola_Kudacki_Linger_1300x.progressive.jpg?v=1586203095 1300w"
               sizes="(max-width: 450px) 650px,
               1300px"
               src="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_Paola_Kudacki_Linger_1300x.progressive.jpg?v=1586203095"
               alt="Tamara Mellon Linger" />
          <p>
            Netflix Binge
            <a
              href="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_Paola_Kudacki_Linger.jpg?v=1586203095"
              download target="_blank" rel="noopener noreferrer">Download</a>
          </p>
          <p>For watching Tiger King at the same time as your best friend.</p>
        </li>
        <li className={styles.item} id="bluesky">
          <img srcSet="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaulaKudacki_BlueSkies_650x.progressive.jpg?v=1586549418 650w,
                   https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaulaKudacki_BlueSkies_1300x.progressive.jpg?v=1586549418 1300w"
               sizes="(max-width: 450px) 650px,
               1300px"
               src="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaulaKudacki_BlueSkies_1300x.progressive.jpg?v=1586549418"
               alt="Tamara Mellon Blue Skies" />
          <p>
            Blue Skies Ahead
            <a
              href="https://cdn.shopify.com/s/files/1/1103/4464/files/TamaraMellon_PaulaKudacki_BlueSkies.jpg?v=1586549418"
              download target="_blank" rel="noopener noreferrer">Download</a>
          </p>
          <p>One day this will all be over, and we’ll walk arm-in-arm in the sun. Until then, this is the next best thing (and you don’t even have to wear sunscreen, unless you’re near a window).</p>
        </li>
        <li className={styles.item}>
          <img src="https://cdn.shopify.com/s/files/1/1103/4464/files/Tamara_coffee-opt.gif?v=1586548006"
               alt="Tamara Mellon Coffee" />
        </li>
      </ul>
    </section>
  )
}

export default Zoom
